<template>

  <div  class="readOnly" id="contractHistoryPage">
    <div class="contract-edit-header">
      <div class="contract-edit-header-item" style="display: flex" id="contract-edit-header-left">
        <ExitBtn data-tag-id="contract-history-backward" :handler="()=>{
          $router.push({name:'contractView',query:{id:contractId,reload:1}})
        }"></ExitBtn>

        <div style="text-align: left;padding-left:20px">

          <contract-title v-if="contract" :contract="contract"
                          :max-length="0"
                          :can-write="false"/>

          <div id="topBar-left-toolBar" >
            <div style="padding-right: 15px; border-right: solid 1px #ddd">
              <div>
                    <span class="stage-circle stage-gray"></span
                    ><span>历史版本</span>
              </div>

            </div>
            <ul
                class="contract-menu"
            >
              <li
                  class="contract-menu-item"
                  @click="FileMenuVisible=!FileMenuVisible" @mouseleave="FileMenuVisible=false" @mouseenter="FileBtnHover"
              >
                <span>文件</span>
                <ul class="dropdown-menu" v-show="FileMenuVisible">
                  <li
                      class="dropdown-menu-item"
                      @click="()=>{
                              globalTagClick('contract-history-copy-version-code')
                             copyToClipboard(contractVersionCode,()=>{
                               ElMessage.info('版本编码已复制到剪切板')
                             });
                          }"
                  >
                    <i></i><span>版本编码:{{contractVersionCode}}</span>
                  </li>
                </ul>
              </li>
              <li
                  class="contract-menu-item"
              >
                <span class="btn-disabled">设置</span>
              </li>
              <li
                  class="contract-menu-item"
                  @click="exportMenuVisible = !exportMenuVisible"
                  @mouseleave="exportMenuVisible = false"
              >
                <span>导出</span>
                <ul v-show="exportMenuVisible" class="dropdown-menu">
                  <li
                      :class="{ unselectable: contract?.currentFileType == 3 }"
                      class="dropdown-menu-item"
                      @click="downloadAsWord"
                  >
                    <i><svg-icon name="word" width="14" height="14"/></i>
                    <span>导出为word</span>
                  </li>
                  <li class="dropdown-menu-item" @click="downloadAsPdf">
                    <i><svg-icon name="pdf" width="14" height="14"/></i>
                    <span>导出为pdf</span>
                  </li>
                </ul>
              </li>
              <HelpListItem
                  data-tag-id="contract-history-help"
                  @click="helpMenuVisible = !helpMenuVisible"
                  @mouseenter="helpBtnHover"
                  @mouseleave="helpMenuVisible = false"
                  :help-menu-visible="helpMenuVisible"
              ></HelpListItem>
            </ul>
          </div>
        </div>

      </div>
      <div class="contract-edit-header-item" id="contract-edit-header-center"></div>
      <div class="contract-edit-header-item" id="contract-edit-header-right">
<!--        <contract-share-->
<!--            :contract="contract"-->
<!--            :team-members="teamMembers"-->
<!--            v-if="teamMembers != null"-->
<!--            :read-only="true"-->
<!--            :versionId="contractVersionId"-->
<!--        >-->

<!--        </contract-share>-->
      </div>
    </div>


    <div class="contract-edit-body custom-scroller" style="display: flex;justify-content: center">

      <div  id="contract-edit-pdfWrap" style="width: 816px; position: relative">
        <div v-if="contractId">
          <pdf-preview
                  :contractId="contractId"
                  :versionId="contractVersionId"
                  :fileId="fileId" v-if="initFinished"
                  ref="pdf-preview"
                  :previewSuccess="pdfPreviewSuccess"
                  @pdfClick="pdfClicked"
                  @pdfPageRendered="pdfPageRendered"
          ></pdf-preview>
        </div>
        <Comment id="commentsPanel" ref="commentsPanel" class="commentsPanel">
          <CommentsInvisibleTipGroup
              :pdf-ref="$refs['pdf-preview']"
              :comments-info-in-pdf="groupedComments"
              :thresholds="{top:0,bottom:0}"
              :position="{
                        top:5,
                        bottom:0
                      }"
              :tip-positions="{
                        top:{
                          top:10,
                          left:100,
                        },
                        bottom:{
                          bottom:10,
                          left:100,
                        }
                      }"
          ></CommentsInvisibleTipGroup>
          <template #comments>
            <div
                v-for="annotation in groupedComments"
                :key="annotation.markId"
                :data-markId="annotation.markId"
                class="commentDialog-container"
            >
              <comment-dialog
                  :ref="`annotations-${annotation.markId}`"
                  :markId="annotation.markId"
                  :comments="annotation.comments"
                  :editorPosition="annotation.editorPosition"
                  :isFocus="focusAnnotationMarkId === annotation.markId"
                  :isHover="hoverAnnotationMarkId === annotation.markId"
                  v-model:permission-mode="sendCommentPermissionMode"
                  @addCommentMessage="addCommentMessage"
                  @deleteAnnotation="deleteAnnotation(annotation.markId)"
                  @deleteCommentMessage="delCommentMessage"
                  @handlePositionChangeEvent="animeAnnotationsPositionTransformation"
                  @resolveAnnotation="resolveAnnotation(annotation.markId)"
                  @sendCommentOperation="sendCommentOperation"
                  v-show="showComment(annotation)"
                  :readonly="commentReadOnly"
                  @updateFocusAnnotationMarkId="
                      (val) => {
                        focusAnnotationMarkId = val;
                      }
                    "
                  @updateHoverAnnotationMarkId="
                      (val) => {
                        hoverAnnotationMarkId = val;
                      }
                    "
              ></comment-dialog>
            </div>
          </template>
        </Comment>
      </div>
    </div>

  </div>


</template>

<script>
import DocContent from "../../components/DocContent";
import {ACTIVE_MODEL} from "../../constant/question";
import {getAllPdfComment, getContractVersion, getContractViewDetail, getTeamUser} from "../../api/api";
import PdfPreview from "../../components/PdfPreview";
// import logo from "../../assets/images/metasotalog.png"
import ContractTitle from "../../components/ContractTitle";
// import contractCSS from "../../assets/css/contract.css";

import $ from "jquery";
import {AlertShowType} from "../../constant/alert";
import {mergeRectsByAdjacent, mergeRectsByVerticalShowType} from "../../assets/js/alert";
import anime from "../../assets/js/anime.es";
import CommentDialog from "../../components/dialog/CommentDialog";
import {CommentPermissionMode} from "../../components/common/constant";
import ProCooperationEditor from "../../components/ProCooperationEditor";
import ContractShare from "../../components/contract/ContractShare";
import {asyncDownloadFile, colorLog, copyToClipboard, MetaMessage} from "../../util";
import config from "../../config";
import {ElMessage} from "element-plus";
import SvgIcon from "../../components/SvgIcon/svgIcon";
import {globalTagClick} from "../../assets/js/tag/tag";
import {CommentType} from "../../assets/js/websocket/websocket-message-type";
import Comment from "../../components/dialog/Comment";
import CommentsInvisibleTipGroup from "../../components/dialog/CommentsInvisibleTipGroup";
import HelpListItem from "../../components/header/HelpListItem";
import ExitBtn from "../../components/universalUI/ExitBtn";

export default {
  name: "ContractHistoryView.vue",
	components:{
    ExitBtn,
    HelpListItem,
    CommentsInvisibleTipGroup,
    Comment, SvgIcon, ContractShare, ProCooperationEditor, PdfPreview,DocContent,ContractTitle,CommentDialog},
  data(){
    return{
      copyToClipboard,
      ElMessage,
      FileMenuVisible:false,
      fileId:'',
      contractId:'',
      versionId:'',
      fileType:'',
      docContent:null,
      docContentMode: ACTIVE_MODEL.ANSWER_MODEL,
      // logo,
      contract: null,
      allComments: [],
      alertHighlightContainerClass: "alertLayer",
      alertHighlightClass: "comment_highlight",
      hoverAnnotationMarkId: null,
      focusAnnotationMarkId: null,
      renderedPageNumbers:new Set(),
      commentReadOnly:true,
      sendCommentPermissionMode:CommentPermissionMode.Internal,
      teamMembers:null,
      metaMessage:new MetaMessage(),
      menuActive:false,
      exportMenuVisible:false,
      helpMenuVisible:false,
      version:{},
      initFinished:false
    }
  },
  created() {
    // this.contract = {title : this.$route.query.fileName.replace(/\.(doc|docx)$/,'')}
  },  computed: {
    contractVersionId(){

      return this.version.id;
    },
    contractVersionCode(){

      return  this.version.versionCode;
    },
    groupedComments() {
      let groupMap = {};
      this.allComments.forEach((comment, index) => {
        let group = groupMap[comment.markId];
        if (group) {
          group.push(comment);
        } else {
          groupMap[comment.markId] = [comment];
        }
      });
      return Object.keys(groupMap)
              .map((markId, index) => {
                // console.log(groupMap[markId]);
                // 过滤掉临时构建的伪数据
                let comments = groupMap[markId]
                return {
                  markId,
                  position: 0,
                  comments: comments,
                  start: groupMap[markId][0].start,
                  end: groupMap[markId][0].end,
                };
              })
              .sort((a, b) => a.start - b.start);
    },
  },
  watch: {

    toolActiveName:function(newVal,oldVal){
      this.allCommentsVisible = newVal === 'comments'
      this.getPdfRef().getPdfInfo().showOrHideAppendLayer(this.allCommentsVisible)
      if (this.allCommentsVisible) {
        this.$nextTick(() => this.refreshCommentsUI())
      }
    },

    focusAnnotationMarkId(newMarkId, oldMarkId) {
      if (this.hoverAnnotationMarkId) {
        this.reverHoverHightlightFn && this.reverHoverHightlightFn();
        this.reverHoverHightlightFn = null;
      }
      let annotationsDom = this.getAllCommentsBox();
      if (newMarkId) {
        [].forEach.call(annotationsDom, (node) => {
          let markId = node.dataset.markid;
          if (newMarkId != markId) {
            this.getAnnotation(markId).minimizeDialog();
          }
        });
      }
      this.highlightAnnotation(newMarkId);
      this.unHighlightAnnotation(oldMarkId);
    },
    hoverAnnotationMarkId(newMarkId, oldMarkId) {
      this.highlightAnnotation(newMarkId);
      this.unHighlightAnnotation(oldMarkId, this.focusAnnotationMarkId);
    },
    allComments: {
      handler: function (newVal, oldVal) {
        this.commentsUpdate(newVal);
      },
      deep: true,
    },
  },
  mounted() {
    this.versionId = this.$route.query.versionId;
    this.contractId = this.$route.query.contractId;
    this.fileType = this.$route.query.fileType;
    console.log(this.versionId)
    getContractVersion(this.versionId).then(res=> {
      this.version=res.data.data;

      this.contract = {
        id: this.contractId,
        title: res.data.data.title
      }
      this.initFinished=true;
    }).catch(err=>{
      console.error("getContractVersion err==>"+err);
      this.metaMessage.error("加载合同信息失败")
    })


  },
  methods:{
    globalTagClick,
    FileBtnHover(){
      if(this.menuActive){
        this.FileMenuVisible=true
      }
    },
    helpBtnHover(){
      if (this.menuActive) {
        this.helpMenuVisible = true;
      }
    },
    loadTeamMember(){
      getTeamUser(this.contract.teamId).then(res=>{
        if (res.data.code === 0){
          this.teamMembers = res.data.data;
        }else {
          ElMessage.error("加载团队成员失败")
        }
      })
    },
    pdfPreviewSuccess: function () {
      this.getAllComments();
    },
    sendCommentOperation:function (messageType, msg){
      const commandType = msg.type;
      if (CommentType.CREATE === commandType) {
        globalTagClick('contract-history-add-comment');
      }else if (CommentType.DELETE === commandType) {
        globalTagClick('contract-history-delete-comment');
      }else if(CommentType.SOLVE === commandType){
        globalTagClick('contract-history-solve-comment');
      }
    },
    addCommentMessage:function () {},
    delCommentMessage:function () {},
    deleteAnnotation:function () {},
    resolveAnnotation:function () {},
    pdfClicked:function(e){
      // console.log('pdfClicked**********',e)
      this.judgeCommentHighlightClick(e)
    },
    judgeCommentHighlightClick: function (e) {
      let commentHighlights = this.getAllCommentsElements();
      //获取点击点在哪些alert的高亮区域里面
      for (let i = 0; i < commentHighlights.length; i++) {
        let highlightEleRect = commentHighlights[i].getBoundingClientRect()
        if (e.clientX <= highlightEleRect.right && e.clientX >= highlightEleRect.left - 3
                && e.clientY <= highlightEleRect.bottom + 3 && e.clientY >= highlightEleRect.top) {
          this.openAnnotation($(commentHighlights[i]).attr('data-id'))
          return
        }
      }
    },
    openAnnotation(markId){
      console.log('focus annotation',markId)
      this.$nextTick(() => {
        let annotation = this.getAnnotation(markId)
        this.focusAnnotationMarkId = markId;
        annotation.openDialog();
        this.$nextTick(() => {
          annotation.createBlurListener();
          this.animeAnnotationsPositionTransformation(markId);
        })
        // 这里延迟150毫秒是等右测评论box移动完成，防止focus导致页面跳到了box移动之前的地方。
        // setTimeout(()=>{
        //   annotation.focusTextArea();
        // },300)
      })
    },
    getAnnotation:function(markId){
      let annotation = this.$refs[`annotations-${markId}`];
      if (annotation && annotation instanceof Array) {
        return annotation[0]
      }
      return annotation;
    },
    getPdfRef() {
      return this.$refs["pdf-preview"];
    },
    showComment:function(annotation){
      return annotation.calculateInfo && annotation.calculateInfo.pageIndexInfo[0] && this.renderedPageNumbers.has(annotation.calculateInfo.pageIndexInfo[0].startPage);
    },
    pdfPageRendered:function(renderPage){
      this.renderedPageNumbers.add(renderPage.pageNumber)
      this.refreshCommentsUI()
      // 由于生成的br节点会影响我们的选取操作，所以我们某一页渲染完毕之后手动删除这些br节点。
      if (renderPage.source && renderPage.source.textLayerDiv) {
        let brNode = renderPage.source.textLayerDiv.querySelectorAll("br");
        if (brNode && brNode.length > 0) {
          brNode.forEach(br => br.remove());
        }
      }
    },
    getAllComments() {
      let pdfRef = this.getPdfRef();
      let pdfInfo = pdfRef.getPdfInfo();
      let pdfContent = pdfInfo.replaceAllSpace(pdfInfo.pagesContent.join(""));
      let previewFileVersionNum = pdfRef.getPreviewFileVersionNum();
      getAllPdfComment(
              this.versionId,
              pdfContent,
              previewFileVersionNum
      ).then((res) => {
        console.log("getAllPdfComment", res);
        if (res.data.code === 0) {
          // this.allComments = res.data.data;
          this.refreshCommentsData(res.data.data);
        }
      });
    },
    refreshCommentsData(data) {
      this.allComments = data;
      this.refreshCommentsUI();
    },
    commentsUpdate(comments) {
      this.refreshCommentsUI();
    },
    refreshCommentsUI() {
      let ranges = this.groupedComments.map((c) => {
        return {start: c.start, end: c.end};
      });
      let convertedRanges = this.getPdfRef().getPdfInfo().fullTextIndexesConvertToPageIndexInfo(ranges);
      this.groupedComments.forEach((info, index) => {
        info.calculateInfo = {};
        info.calculateInfo.pageIndexInfo = [convertedRanges[index]];
        info.id = info.markId;
      });
      // console.log("refresh comments ui***************************");
      this.refreshErrorHighlight(
              this.groupedComments,
              this.getCommentLineBoxType,
              this.getCommentHighlightClassName
      );
      // console.log("refresh comments ui***************************");
      this.$nextTick(() => {
        this.resetAnnotationsPosition();
      });
    },
    refreshErrorHighlight: function (
            groupedComments,
            getLineBoxFunc,
            getHighlightClassNameFunc
    ) {
      //删除旧的highlight节点
      $(`.${this.alertHighlightClass}`).remove();
      if (groupedComments.length === 0) {
        return;
      }
      const self = this;
      const pdfInfo = this.getPdfRef().getPdfInfo();
      //先获取所有加载完毕的页码
      const visiblePageNum = pdfInfo.getRenderFinishPageNum(true);
      //根据已经加载出来的页码，来过滤能够显示出来的错误提示
      let canMarkComments = groupedComments.filter((a) => {
        if (!a.calculateInfo.pageIndexInfo) {
          return false;
        }
        return (
                visiblePageNum.indexOf(a.calculateInfo.pageIndexInfo[0].startPage) !==
                -1
        );
      });
      //在已经加载出的页面内画出对应错误提示。
      canMarkComments.forEach((markInfo) => {
        markInfo.calculateInfo.pageIndexInfo.forEach((pageIndexInfo) => {
          //将页内坐标（包含空格）修复成页内元素坐标（不包含空格）
          const pageRanges = pdfInfo.getFixedPageRanges(pageIndexInfo);
          if (pageRanges == null) {
            //说明该页尚未加载完成，也就不用画错误提示。
            return;
          }
          //根据该坐标去取到对应的document的range对象。
          let documentRanges = pdfInfo.getRangeAndAppendLayers(
                  pageRanges,
                  visiblePageNum,
                  this.allCommentsVisible
          );
          documentRanges.forEach((rangeInfo) => {
            //根据range对象对应的矩形区域画出错误提示。
            const lineBoxType = getLineBoxFunc(markInfo);
            const highlightClassName = getHighlightClassNameFunc(markInfo);
            self.createMarker(
                    rangeInfo.appendLayer,
                    rangeInfo.documentRange.getClientRects(),
                    markInfo.id,
                    lineBoxType,
                    highlightClassName
            );
          });
        });
      });
    },
    getCommentLineBoxType: function () {
      return AlertShowType.HORIZONTAL;
    },
    getCommentHighlightClassName: function (comment) {
      let classArray = [
        "_3F-Wk",
        "comment_marker_color_1",
        this.alertHighlightClass,
      ];
      if (comment.markId === this.focusAnnotationMarkId) {
        classArray.push("_3GrEs");
      }
      return classArray.join(" ");
    },
    getAllCommentsElements(){
      return $(`.${this.alertHighlightClass}`)
    },
    createMarker: function (
            appendLayer,
            selectRangeRects,
            id,
            lineBoxType,
            alertLinBoxClasses
    ) {
      const offset = appendLayer.offset();
      let leftOffset = offset.left;
      let topOffset = offset.top;
      const self = this;
      // const lineBoxType = this.getAlertShowType(markInfo)
      // const alertLinBoxClasses = self.getAlertHighlightClassName(markInfo);
      selectRangeRects = Array.from(selectRangeRects)
              .filter((rect) => {
                return rect.width !== 0 && rect.height !== 0;
              })
              .sort((a, b) => {
                if (Math.abs(a.top - b.top) > (a.height + b.height) * 0.5 * 0.5) {
                  return a.top - b.top;
                }
                if (a.left !== b.left) {
                  return a.left - b.left;
                }
                return 0;
              });
      const mergedRects =
              lineBoxType === AlertShowType.VERTICAL
                      ? mergeRectsByVerticalShowType(selectRangeRects, offset)
                      : mergeRectsByAdjacent(selectRangeRects);
      for (let i = 0; i < mergedRects.length; i++) {
        let mergedRect = mergedRects[i];
        appendLayer.append(
                `<div class='${alertLinBoxClasses}' data-id="${id}" style='left:${
                        mergedRect.left - leftOffset
                }px;top:${mergedRect.top - topOffset}px;width:${
                        mergedRect.width
                }px;height:${mergedRect.height}px;'></div>`
        );
      }
    },
    highlightAnnotation(markId) {
      if (!markId) {
        return;
      }
      this.getCommentsElements(markId).addClass("_3GrEs");
    },
    unHighlightAnnotation(markId, excludeMarkId) {
      if (!markId) {
        return;
      }
      this.getCommentsElements(markId).removeClass("_3GrEs");
    },
    //*****************高亮左侧文本部分
    //*****************右侧评论box位置计算相关
    resetAnnotationsPosition() {
      /**
       * 静态改变位置
       */
      let {newInlineAnnotations, annotationsDom} =
              this.recomputeAnnotationsPosition();
      newInlineAnnotations.forEach((newInfo, index) => {
        let targetNode = [].find.call(
                annotationsDom,
                (node) => node.dataset.markid == newInfo.markId
        );
        targetNode.style.top = newInfo.position + 10 + "px";
        Object.assign(this.groupedComments[index], newInfo);
      });
      // this.groupedComments = newInlineAnnotations.reduce((newAnnotations, newInfo, index) => {
      //   let targetNode = [].find.call(annotationsDom, (node) => node.dataset.markid == newInfo.markId);
      //   targetNode.style.top = newInfo.position + 10 + 'px';
      //   newAnnotations.push(Object.assign({}, this.groupedComments[index], newInfo));
      //   return newAnnotations;
      // }, []);
    },
    recomputeAnnotationsPosition() {
      let annotationsDom = this.getAllCommentsBox();
      let classfiedComments = this.getClassfiedCommentWithEditorPosition();
      let lastElHeight;
      let lastStartPosition;
      let orderlyTop = Object.keys(classfiedComments).sort(
              (a, b) => Number(a) - Number(b)
      );

      let newInlineAnnotations = this.groupedComments.map((a) => ({
        markId: a.markId,
        position: a.position,
      }));

      orderlyTop.forEach((topValue, index) => {
        classfiedComments[topValue].forEach((annotationInfo, idx) => {
          let {position, markId} = annotationInfo;
          if (index !== 0 || idx !== 0) {
            if (lastStartPosition + lastElHeight + 5 > position) {
              //5是annotation之间最小距离
              position = lastStartPosition + lastElHeight + 5;
            }
          }
          let targetInnotation = newInlineAnnotations.find(
                  (annotation) => annotation.markId === markId
          );
          targetInnotation.position = position;

          lastStartPosition = position;
          // console.log({annotationsDom, markId})
          lastElHeight = [].find
                  .call(annotationsDom, (node) => node.dataset.markid == markId)
                  .getBoundingClientRect().height;
        });
      });
      return {newInlineAnnotations, annotationsDom};
    },
    animeAnnotationsPositionTransformation(newMarkId) {
      let {newInlineAnnotations, annotationsDom} =
              this.resetPositionWithFocus();
      let that = this;
      anime({
        targets: annotationsDom,
        top: function (el, i) {
          let targetMarkId = el.dataset.markid;
          let newPosition = newInlineAnnotations.find((annotationData) => {
            return annotationData.markId == targetMarkId;
          }).position;
          if (newMarkId == el.dataset.markid) {
            return [newPosition, newPosition + 10];
          } else {
            return newPosition + 10;
          }
        },
        left: function (el) {
          let targetMarkId = el.dataset.markid;
          if (newMarkId || that.focusAnnotationMarkId) {
            if (
                    newMarkId == targetMarkId ||
                    that.focusAnnotationMarkId == targetMarkId
            ) {
              return 0;
            } else {
              return 15;
            }
          } else {
            return 0;
          }
        },
        opacity: function (el) {
          let targetMarkId = el.dataset.markid;
          if (newMarkId || that.focusAnnotationMarkId) {
            if (
                    newMarkId == targetMarkId ||
                    that.focusAnnotationMarkId == targetMarkId
            ) {
              return 1;
            } else {
              return 0.7;
            }
          } else {
            return 1;
          }
        },
        duration: 100,
        easing: "linear",
      });
    },
    getAllCommentsBox() {
      return this.$refs.commentsPanel.$el.querySelectorAll(".commentDialog-container");
    },
    getCommentsElements: function (markId) {
      return $(`.comment_highlight[data-id=${markId}]`);
    },
    // 返回comments相对于editor的偏移
    getCommentsElementOffsetEditor: function (commentsId) {
      let editor = $(`.pdf-container`);
      let editorOffset = editor.offset().top;
      let editorScrollTop = editor.scrollTop();
      let commentsElements = this.getCommentsElements(commentsId);
      if (commentsElements.length === 0) {
        return 0;
      }
      return ($(commentsElements[0]).offset().top - editorOffset + editorScrollTop);
    },
    getClassfiedCommentWithEditorPosition() {
      let classfiedComments = this.groupedComments.reduce(
              (classfied, annotation, index) => {
                let top = this.getCommentsElementOffsetEditor(annotation.markId);
                let group = classfied[top];
                if (group) {
                  group.push({
                    markId: annotation.markId,
                    position: top,
                  });
                } else {
                  classfied[top] = [
                    {
                      markId: annotation.markId,
                      position: top,
                    },
                  ];
                }
                return classfied;
              },
              {}
      );
      return classfiedComments;
    },
    resetPositionWithFocus() {
      let annotationsDom = this.getAllCommentsBox();
      let classfiedComments = this.getClassfiedCommentWithEditorPosition();
      let orderlyTop = Object.keys(classfiedComments).sort(
              (a, b) => Number(a) - Number(b)
      );
      let newInlineAnnotations = this.groupedComments.map((a) => ({
        markId: a.markId,
        position: a.position,
      }));

      let offset = 0;
      let lastElHeight;
      let lastStartPosition;
      orderlyTop.forEach((topVal, index) => {
        classfiedComments[topVal].forEach((annotationInfo, idx) => {
          let {markId, position} = annotationInfo;
          if (index !== 0 || idx !== 0) {
            if (lastStartPosition + lastElHeight + 5 > position && markId !== this.focusAnnotationMarkId) {
              //5是annotation之间最小距离
              position = lastStartPosition + lastElHeight + 5;
            } else if (markId === this.focusAnnotationMarkId) {
              position = this.getCommentsElementOffsetEditor(markId);
              if (position < lastStartPosition + lastElHeight + 5) {
                offset = position - (lastStartPosition + lastElHeight + 5);
              }
              newInlineAnnotations.forEach((annotationInfo) => {
                let targetAnnotationEditorPosition = this.getCommentsElementOffsetEditor(annotationInfo.markId);
                if (annotationInfo.markId !== this.focusAnnotationMarkId && targetAnnotationEditorPosition <= position) {
                  annotationInfo.position = annotationInfo.position + offset;
                }
              });
            }
          } else {
            position = this.getCommentsElementOffsetEditor(markId);
          }
          let targetInnotation = newInlineAnnotations.find(
                  (annotation) => annotation.markId === markId
          );
          targetInnotation.position = position;
          lastStartPosition = position;
          // console.log({annotationsDom, markId})
          lastElHeight = [].find
                  .call(annotationsDom, (node) => node.dataset.markid == markId)
                  .getBoundingClientRect().height;
        });
      });
      return {newInlineAnnotations, annotationsDom};
    },
    getExportUrl: function (type) {
      return config.baseUrl + `/contract/${this.contract.id}/contract-version/${this.contractVersionId}/export?type=${type}`;
    },

    downloadAsWord() {
      globalTagClick('contract-view-download-word');
      asyncDownloadFile(this.getExportUrl("docx"));
    },
    downloadAsPdf() {
      globalTagClick('contract-view-download-pdf');
      asyncDownloadFile(this.getExportUrl("pdf"));
    },
    //*****************右侧评论box位置计算相关
  }
}
</script>

<style scoped>
@import  "../../assets/css/contractHeader.css";
.readOnly{
  height: 100%;
  width:100%;
}
.contract-edit-body{
  overflow-y: scroll;
  height: calc(100% - var(--contractEditHeaderHeight) - 1px);
}
.pdf-tool{
  position: sticky;
  top: 0;
  z-index: 10;
  background: white;
  border-bottom: solid 1px #dddddd;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
}
.btn-disabled{
  color:#ccc;
}
.contract-edit-header{

}
/* 一些从contract.css提取的样式 */
.stage-circle {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  display: inline-block;
  margin-right: 10px;
}
.stage-gray {
  background-color: #bbbbbb;
}
.contract-menu {
  display: flex;
  list-style: none;
  margin: 0 0 0 5px;
  padding: 0;
}

.contract-menu .contract-menu-item {
  display: inline-block;
  flex-shrink: 0;
  padding: 3px 10px;
  border-radius: 2px;
  cursor: pointer;
  position: relative;
}

.contract-menu .contract-menu-item:hover {
  background: #eaeaea;
}

.contract-edit-content-container{
  height: 100%;
  width: 100%;
}

/*.contract-edit-content-container >>> .metaProFileTitleDiv{*/


/*  top: 80px;*/
/*  z-index: 100;*/

/*  position: sticky;*/
/*  background: white;*/
/*}*/

.tool-container{
  width: 446px;


  margin: 0px 40px 0 28px;
}
.contract-edit-content-container >>> #editor-header{
  position: sticky;
  top: 0px;
  z-index: 100;
  transition:.5s box-shadow;
  /*border-bottom: solid 1px #dddddd;*/
}

.contract-edit-content-container >>> #editor-header .ms-header__3Lq7hH_vrUijrkNLjWmBrR{
  background: white;
}

.contract-edit-content-container >>> .ms-header-subfield__1c4ptY7A9DNBFOCBbiUfQj div{
  background: white;
}
.tool-container >>> .el-tabs .el-tabs__nav .el-tabs__item {
  padding: 4px 10px;
  border-radius: 2px;
  color: #999;

}

.tool-container >>> .el-tabs .el-tabs__nav .el-tabs__item.is-active {
  background: #E4EBFF;
  color: #202124;
  line-height: unset;
  height: auto;
}

.tool-container >>> .el-tabs .el-tabs__nav-wrap::after {
  display: none;
}

.tool-container >>> .el-tabs .el-tabs__active-bar {
  display: none;
}

#contract-edit-header-center {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  flex-grow: 0;
}

</style>

<style lang="scss">
  @import url("../../assets/css/alert.css");
  @import url("../../assets/css/comment.css");

  #contractHistoryPage .commentsPanel {
    right: -228px;
  }
</style>
